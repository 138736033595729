import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Is = require("Everlaw/Core/Is");

// Subset of params that window.open understands (varies by browser).
export interface OpenParams {
    top?: number;
    left?: number;
    width?: number;
    height?: number;
    menubar?: boolean;
    resizable?: boolean;
    scrollbars?: boolean;
    status?: boolean;
    toolbar?: boolean;
}

export function open(url: URL, params?: string, errDesc = "window") {
    const w = window.open(url.href, "_blank", params);
    if (!w) {
        Dialog.ok(
            "Popup blocked",
            Dom.div(
                Dom.p(
                    'We were unable to open "'
                        + errDesc
                        + '". Please ensure you are allowing all pop-ups from '
                        + window.location.hostname,
                ),
                Dom.p(
                    Dom.b("Note: "),
                    "Be sure to allow popups generally from "
                        + window.location.hostname
                        + " rather than just this specific one.",
                ),
            ),
            undefined,
            "450px",
        );
    }
    return w;
}

export function openWithParams(url: URL, params: OpenParams, errDesc = "window") {
    const props: string[] = [];
    Object.entries(params).forEach(([tag, val]) => {
        if (Is.number(val)) {
            props.push(tag + "=" + val);
        } else {
            props.push(tag + "=" + (val ? "yes" : "no"));
        }
    });
    return open(url, props.join(","), errDesc);
}

// For popups that should be centered in the screen such as logins and filepickers for cloud uploads.
export function openCentered(
    url: URL,
    errDesc = "window",
    width = 1000,
    height = 800,
    params?: OpenParams,
) {
    if (params == null) {
        params = {};
    }
    params.width = Math.min(width, window.screen.availWidth);
    params.height = Math.min(height, window.screen.availHeight);
    params.top = Math.max(
        window.screenY + (window.outerHeight - params.height) / 2,
        window.screenY,
    );
    params.left = Math.max(window.screenX + (window.outerWidth - params.width) / 2, window.screenX);
    params = Object.assign(
        {
            status: false,
            menubar: false,
            toolbar: false,
            resizable: true,
            scrollbars: true,
        },
        params,
    );
    return openWithParams(url, params, errDesc);
}

export function autoClickHref(...hrefParams: Parameters<typeof Dom.a>) {
    const a = Dom.place(Dom.a(...hrefParams), document.body);
    a.click();
    Dom.destroy(a);
}
