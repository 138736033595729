import Dom = require("Everlaw/Dom");
import dojo_window = require("dojo/window");

class Popup {
    tracker: HTMLElement;
    private lastPos: { x: number; y: number } | null = null;
    private timer: number | null = null;
    content: HTMLElement;
    direction: string;
    reference: HTMLElement;
    private attachToMidReference = false;
    private zIndex: string;
    private matchWidth = true;
    private forceReposition = false;
    private maxHeight: number;
    private offset: { x: number; y: number } = { x: 0, y: 0 };
    private forceDirection = false;

    constructor(params: Popup.Params) {
        Object.assign(this, params);
        // this.tracker tracks the vertical position of reference, so content can be attached to
        // either its top or bottom. We give it the dijitPopup class so that Dojo dialogs don't
        // steal focus when this popup is displayed over them.
        this.tracker = Dom.create(
            "div",
            {
                class: "default-hidden dijitPopup",
                style: { position: "absolute", width: "0" },
            },
            document.body,
        );
        this.content.style.zIndex = this.zIndex;
        Dom.place(this.content, this.tracker);
        Dom.hide(this.content);
    }
    show() {
        Dom.show(this.content);
        // Since the popup is not attached to the reference node in the DOM, we need to make sure
        // that it stays in the right position as the user resizes or scrolls the window. So we
        // check its position every 25ms and re-place it if necessary.
        this.lastPos = null;
        this.positionPopup();
        if (this.timer === null) {
            this.timer = setInterval(() => {
                this.positionPopup();
            }, 25);
        }
    }
    hide() {
        Dom.hide(this.content);
        if (this.timer !== null) {
            clearInterval(this.timer);
            this.timer = null;
        }
    }
    isHidden() {
        return Dom.isHidden(this.content);
    }
    toggle() {
        this.isHidden() ? this.show() : this.hide();
    }

    positionPopup() {
        const pos = Dom.position(this.reference, true);
        pos.x = pos.x + this.offset.x;
        pos.y = pos.y + this.offset.y;
        if (pos.x === 0 && pos.y === 0) {
            Dom.addClass(this.tracker, "default-hidden");
            // Usually this means that the widget is actually hidden
            return;
        }
        Dom.removeClass(this.tracker, "default-hidden");
        if (
            !this.forceReposition
            && this.lastPos
            && pos.x === this.lastPos.x
            && pos.y === this.lastPos.y
        ) {
            return;
        }
        this.lastPos = pos;
        const windowBox = dojo_window.getBox();
        const menuPos = Dom.position(this.content);

        Dom.style(this.tracker, { top: pos.y + "px", height: pos.h + "px" });

        const style = this.content.style;
        if (this.matchWidth) {
            style.minWidth = pos.w + "px";
        }
        style.left = pos.x + "px";

        const menuBottom = pos.y + pos.h + menuPos.h;
        const windowBottom = windowBox.h + windowBox.t;
        const attachPoint = this.attachToMidReference ? "50%" : "100%";
        if (
            (this.direction === "after" && (this.forceDirection || menuBottom <= windowBottom))
            || (menuPos.h > pos.y && !this.forceDirection)
        ) {
            style.top = attachPoint;
            style.bottom = "";
            const maxHeight = this.calcMaxHeight(windowBottom, pos);
            if (maxHeight !== null) {
                style.maxHeight = maxHeight + "px";
            }
        } else {
            style.position = "absolute";
            style.top = "";
            style.bottom = attachPoint;
            style.maxHeight = (this.maxHeight ? Math.min(pos.y, this.maxHeight) : pos.y) + "px";
        }

        if (pos.x + menuPos.w > windowBox.w) {
            style.left = windowBox.w - menuPos.w + "px";
        }
    }
    // Used to detect whether the popup will place itself before or after the reference based on
    // the available space. Not neccessary if forceDirection is true.
    getPosition() {
        const pos = Dom.position(this.reference, true);
        pos.x = pos.x + this.offset.x;
        pos.y = pos.y + this.offset.y;
        const menuPos = Dom.position(this.content);
        const menuBottom = pos.y + pos.h + menuPos.h;
        const windowBox = dojo_window.getBox();
        const windowBottom = windowBox.h + windowBox.t;
        if (
            (this.direction === "after" && (this.forceDirection || menuBottom <= windowBottom))
            || (menuPos.h > pos.y && !this.forceDirection)
        ) {
            return "after";
        } else {
            return "before";
        }
    }

    /**
     * Calculates the max height base on available real-estate below the reference box.
     *
     * Note that menuBottom is not reliable, as the menu might not be populated when this
     * code runs (it's on the way to get it from the server), so setting this maxHeight limit
     * regardless of whether menuBottom > windowBottom or not. (see positionPopup())
     *
     * Doing this only in forceDirection case since I don't want to somehow break other
     * cases, but would be worth to check if this solution is good in general.
     */
    private calcMaxHeight(
        windowBottom: number,
        refBox: { w: number; h: number; x: number; y: number },
    ): number | null {
        if (!this.forceDirection) {
            return null;
        }
        // Subtracting 10 so it doesn't go exactly to the end, doesn't look good.
        const availHeight = windowBottom - refBox.y - refBox.h - 10;

        // Using 400 as default since it's what is set for .select-menu class.
        // Otherwise if we had set it to availHeight in the absence of this.maxHeight,
        // it would have overridden the 400 and we'd get an ugly dropdown all the way down.
        return Math.min(availHeight, this.maxHeight || 400);
    }

    destroy() {
        this.hide(); // clears timer
        Dom.destroy(this.tracker);
    }
    setOffset(offset: { x: number; y: number }) {
        this.offset = offset;
    }
}

module Popup {
    export interface Params {
        content: HTMLElement; // This element should have CSS position: absolute
        direction: string; // "after" or "before"
        reference: HTMLElement;
        /**
         * Whether to attach the top (or bottom) of the popup with the vertical midpoint of the reference.
         * Default is false, which means it would attach to the bottom of the reference if it's going down,
         * or to the top if it's going up.
         */
        attachToMidReference?: boolean;
        zIndex: string;
        matchWidth?: boolean; // Popup takes the width of the reference node.
        forceReposition?: boolean;
        maxHeight?: number; // in px
        forceDirection?: boolean; // Should popup always be in the specified direction, regardless of position?
    }
}

export = Popup;
