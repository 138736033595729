import Arr = require("Everlaw/Core/Arr");
import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import Dom = require("Everlaw/Dom");

/** Parent of SingleSelect and ComboBox */
abstract class BaseSingleSelect<T extends Base.Object> extends BaseSelect<T, T> {
    initialSelected: T;
    getElementsAsArray() {
        // Returns all elements in the order they appear in this selector.
        return Arr.flat<T>(
            this.classOrderStr.map((clazz) => this._elements[clazz].elements.map((e) => e.element)),
        );
    }
    select(elem: T, silent?: boolean, alsoSetValue?: boolean, readOnlyOverride?: boolean) {
        // Parameter order switched because SingleSelect uses alsoSetValue,
        // but MultiSelect does not.
        this.toggle(elem, silent, true, readOnlyOverride, alsoSetValue);
    }
    unselect(elem?: T, silent?: boolean) {
        if (!elem) {
            if (this.getSelected()) {
                this.toggle(this.getSelected(), silent, false);
            }
        } else {
            this.toggle(elem, silent, false);
        }
    }
    scrollToSelected(): void {
        if (this._selected) {
            let index = 0;
            let length = 0;
            // Search through every sublist for _selected because elements is always shaped as T[][],
            // even if elements: T[] in params.
            for (let i = 0; i < this.elements.length; i++) {
                index = this.elements[i].indexOf(this._selected);
                length = this.elements[i].length;
                if (index > -1) {
                    break;
                }
                index = 0;
            }
            const quotient = index / length;
            const height = this.menu.scrollHeight * quotient;
            // scrollTo doesn't work on IE, so we always scroll to the top in that case
            if (this.menu.scrollTo) {
                this.menu.scrollTo({ top: height });
            } else {
                this.scrollToTop();
            }
        }
    }
    protected doKeySelect() {
        if (this._selected) {
            this.setKeySelectToElement(this._selected);
            // The use of setTimeout here is a hack to trigger text selection
            // after the onClick event. If that order is reversed, text is
            // selected and then immediately unselected by the click event.
            setTimeout(() => {
                this.tb.select();
            }, 0);
        } else {
            this.drawSelected();
        }
    }
    protected override selectInitial(silent = true) {
        if (this.initialSelected) {
            this.select(this.initialSelected, silent, true, true);
        }
    }
    protected override toggleInner(
        node: Dom.Nodeable,
        elem: T,
        silent: boolean,
        add: boolean | MouseEvent,
        readOnlyOverride?: boolean,
        alsoSetValue?: boolean,
    ) {
        super.toggleInner(node, elem, silent, add, readOnlyOverride);
        if (add && alsoSetValue) {
            this.setValue(elem, silent);
        }
    }
    protected _onToggleInner(elem: T, add: boolean, silent: boolean) {
        if (add && !this.isAllowMultiSelect) {
            if (this._selected) {
                this.toggle(this._selected, silent, false);
            }
            this._selected = elem;
        } else {
            if (this._selected === elem) {
                this._selected = null;
            }
        }
    }
    protected _onSelect(elem: T | string, isNew?: boolean, selectedNode?: Dom.Nodeable) {
        if (!this.stayFilteredOnSelect) {
            this.setValue(this.display(elem));
        }
        if (!isNew) {
            if (!this.manualFilter && !this.stayFilteredOnSelect) {
                this._filter("");
            }
            Object.values(this.newNodes).forEach((newNode) => Dom.removeClass(newNode, "selected"));
        }
        this.onSelect(elem, isNew, this, selectedNode);
        this.onChange(elem, true, this._selected);
    }
    protected _onUnselect(elem: T, isNew?: boolean, unselectedNode?: Dom.Nodeable) {
        this.onUnselect(elem, unselectedNode);
        this.onChange(elem, false, this._selected);
    }
}

module BaseSingleSelect {
    export interface Params<T extends Base.Object> extends BaseSelect.Params<T, T> {
        // element(s) silently selected during construction of this widget, ignores readOnly status
        initialSelected?: T;
        // When the user clicks on an already-selected item, if selectOnSame is true, we treat that
        // click as a selection instead of a deselection. Use this option if you don't want the user to
        // be able to deselect (for example, if one of the options is "Any", it makes sense to be unable
        // to deselect).
        // NB: this option is only available in SingleSelect because deselecting is an intrinsic aspect
        // of a MultiSelect.
        selectOnSame?: boolean;
    }

    /**
     * A simple table of options which you can select one item. Kept in this file for namespace reasons.
     * TODO: add keyboard navigation and an appropriate aria role
     */
    export class SingleWithToggler<T extends Base.Object> extends BaseSingleSelect<T> {
        constructor(params: Params<T>) {
            super(params);
        }
        override getValue(): T {
            return <T>super.getValue();
        }
        hasTextbox() {
            return false;
        }
        override onSelect(elem: T, isNew?: boolean, self?: BaseSingleSelect<T>) {}
        override onChange(elem: T, wasAdded: boolean, allSelected: T) {}
    }
}

export = BaseSingleSelect;
